import React,{Component} from 'react'
import './App.css';
import  logo from './imgs/andriosLogo.png'
import  pic1 from './imgs/back.png'
import shbibaPic from './imgs/projects/shbiba.png'
import manaaPic from './imgs/projects/manaa.png'
import atidimPic from './imgs/projects/atidim.png'
import seeds from './imgs/projects/seeds.png'
import yomcom from './imgs/projects/yomcom.png'
import ipanel from './imgs/projects/ipanel.png'

import  appIcon from './imgs/coding.png'
import  brainIcon from './imgs/brain.png'
import  planningIcon from './imgs/planning.png'
import  supportIcon from './imgs/support.png'
import consultingImg from './imgs/com.jpg'
import img2 from './imgs/consulting.jpg'
import devImg from './imgs/dev4.jpg'
import supportImg from './imgs/support.jpg'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Carousel} from "react-bootstrap";

export default class HomePage extends Component {
    constructor() {
        super();
    }
    componentDidMount() {

    }
    render() {
        return (
            <div>
                <nav className="navbar navbar-expand-sm  navbar-dark fixed-top" id='header'>

                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" href="#section1">דף הבית</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#section2">פיתוח תוכנה</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#section3">צור קשר</a>
                        </li>

                    </ul>

                        <img src={logo} id='headerLogo'/>


                </nav>
                <div  id='page'>
                    <div id="section1" className="container-fluid " style={{paddingLeft:0,paddingRight:0}}>
                        <img src={pic1} style={{width:'100%'}}/>
                        <div id='pageContent' style={{marginTop:'30px'}}>
                             <p class='text'>אנחנו מציעים מגון רחב של פתרונות תוכנה לעסקים ולארגונים ולכל מטרה אחרת תחת הפקדה על איכות התוכנה ואיכות השירות </p>
                            <br/>
                            <div class={'row'} style={{width:'fit-content',margin:'auto'}}>
                                <Carousel>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={seeds}
                                            alt="First slide"
                                        />
                                        <Carousel.Caption style={{backgroundColor:'#fff'}}>
                                            <h3 className={'captionHeader'}>Seeds of Humanity</h3>
                                            <p className={'captionText'}>אתר אינטרנט לעמותה</p>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={shbibaPic}
                                            alt="First slide"
                                        />
                                        <Carousel.Caption style={{backgroundColor:'#fff'}}>
                                            <h3 className={'captionHeader'}>יחידת הנוער שפרעם</h3>
                                            <p className={'captionText'}>אפליקציה להרשמת קורסים</p>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={manaaPic}
                                            alt="Third slide"
                                        />

                                        <Carousel.Caption style={{backgroundColor:'#fff'}}>
                                            <h3 className={'captionHeader'}>מענא</h3>
                                            <p className={'captionText'}>אפליקציה שמנהלת חומרים הארגון</p>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={atidimPic}
                                            alt="Third slide"
                                        />

                                        <Carousel.Caption style={{backgroundColor:'#fff'}}>
                                            <h3 className={'captionHeader'}>עתידים</h3>
                                            <p className={'captionText'}>אפליקציה שמשתפת קבצים עבור הארגון</p>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={yomcom}
                                            alt="Third slide"
                                        />

                                        <Carousel.Caption style={{backgroundColor:'#fff'}}>
                                            <h3 className={'captionHeader'}>יוםקום</h3>
                                            <p className={'captionText'}>אפליקציה שמנהלת נוכחות עובדים בשטחי עבודה שונים</p>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={ipanel}
                                            alt="Third slide"
                                        />

                                        <Carousel.Caption style={{backgroundColor:'#fff'}}>
                                            <h3 className={'captionHeader'}>ipanel</h3>
                                            <p className={'captionText'}>אפליקצית מילוי סקרים</p>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </div>
                    </div>

                    <div id="section2" className="container-fluid " style={{paddingBottom: '70px', paddingTop: '70px'}}>
                        <div id='pageContent'>
                            <h1 className={'sectionTitle'}>פיתוח תוכנה</h1><br/>
                            <div className={'row'}>
                                <div className={'col-sm-6'}>
                                    <p style={{textAlign:'right',fontSize:'18px'}}>אנחנו באנדריוס מפתחים אפליקציות ואתרים לעסקים ולארגונים.הדגש שלנו הוא איכות התוכנה והתמיכה כך שהתוכנה שלנו עוזרת לעסק שלכם להצליח ואנחנו עובדים בכמה תחומים שונים כך שתמיד יש לנו את הפתרון עבורכם</p>
                                    <p style={{textAlign:'right',fontSize:'18px'}}>מה הפתרונות שלנו בעצם? </p>
                                    <ul>
                                        <li>אפליקציות לניהול ארגונים ועסקים</li>
                                        <li>מערכות ניהול CRM</li>
                                        <li>אפליקציות ואתרים לשיווק או מכירות</li>
                                        <li>אתרי תדמית</li>
                                        <li>תוכניות כשירות SaaS</li>
                                        <li>שירותי אחסון וענן</li>
                                    </ul>
                                    <p style={{textAlign:'right',fontSize:'18px'}}>אז יש לכם עסק ? מוזמנים להתרשם צור איתנו קשר ובחיד נבנה את העתיד שלכם </p>
                                </div>
                                <div className={'col-sm-6'}>
                                    <img src={devImg} className={'sectionImg'}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="section3" className="container-fluid " style={{paddingBottom: '70px', paddingTop: '70px'}}>
                        <div id='pageContent'>
                            <h1 className={'sectionTitle'}>צור קשר</h1><br/>
                            <div className={'row'}>
                                <div className={'col-sm-6'}>
                                    <div id={'contactForm'}>

                                        <div>
                                            <img src={require('./imgs/smartphone.png')}/>
                                            <p style={{display: 'inline-block', marginRight: 5, fontSize: 18}}><a
                                                href="tel:0546123203">054-6123203</a></p>
                                        </div>
                                        <div>
                                            <img src={require('./imgs/mail.png')}/>
                                            <p style={{display: 'inline-block', marginRight: 5, fontSize: 18}}><a
                                                href="mailto:info@andriosapps.net">info@andriosapps.net</a></p>
                                        </div>
                                        <div>
                                            <img src={require('./imgs/pin.png')}/>
                                            <p style={{display: 'inline-block', marginRight: 5, fontSize: 18}}>אכסאל</p>
                                        </div>
                                        <input type='text' className='myinput' placeholder='שם מלא'/>
                                        <input type='text' className='myinput' placeholder='מספר טלפון'/>
                                        <textarea type='text' className='myinput' id='mytextarea' placeholder=''/>
                                    </div>
                                </div>
                                <div className={'col-sm-6'}>
                                    <img src={supportImg} className={'sectionImg'} style={{marginBottom:10}}/>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
//style="padding-top:70px;padding-bottom:70px"
//                                    <img src={supportImg} className={'sectionImg'} style={{marginBottom:10}}/>
